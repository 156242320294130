<template>
  <div
    class="list"
    :style="
      'background-color: ' + bgColor + ';padding-top: ' + paddingTop + 'px;'
    "
    ref="list"
  >
    <template v-if="items.length > 0">
      <slot
        v-for="(item, key) in itemsInView"
        :key="key"
        name="item"
        :data="item"
        :key_="key + startSlice"
        :extra="extras ? extras[key] : null"
        :read="readItem"
      ></slot>
    </template>

    <template v-if="!loading && items.length <= 0 && !moreContentExists">
      <slot name="no-result"> </slot>
    </template>

    <template v-if="!loading && items.length <= 0 && networkError_">
      <slot name="network-error"></slot>
    </template>

    <template v-if="loading || loadJam">
      <slot
        v-for="index in 3"
        :key="index"
        name="tombstone"
        :loading="loading"
      ></slot>
    </template>

    <!-- <div id="loading-more" v-if="moreContentExists">
      <ion-spinner name="dots"></ion-spinner>
    </div> -->
  </div>
</template>

<script>
import { reactive } from "vue";

function initState() {
  return {
    items: reactive([]),
    moreContentExists: true,
    startSlice: 0,
    endSlice: null,
    paddingTop: 0,
    loading: true,
    loadJam: false,
    networkError_: false,
    reloadTimeout: null,
    percentage_down: null,
    paddingTops: [],
    parentEl: null,
  };
}

export default {
  props: [
    "size",
    "loadData",
    "itemHeight",
    "itemUniqueClass",
    "itemMargin",
    "bgColor",
    "parentElementClass",
    "name",
    "action",
    "actionHandler", //A custom function (e.g mutation function to remove an item) to run when 'actionTrigger' goes off
    "actionTrigger", //A watched prop to run the function on change
    "ready",
    "extras",
    "ParentListScrollHandler", //Function to be called in parent page as recycler list is scrolled
  ],
  inject: ["store"],
  emits: ["scrolledToTop", "recyclerInstance", "initialized"],
  data() {
    return initState();
  },

  methods: {
    //Remove event listener from parent scroll
    unbindParentScroll() {
      this.parentEl.removeEventListener("scroll", this.scrollHandler);
    },

    //Attach scroll event listener to the latest parent scroll DOM
    bindToLatestParentScrollDOM() {
      let self = this;
      self.parentEl = document.getElementsByClassName(
        self.parentElementClass
      )[0];
      self.parentEl.addEventListener("scroll", self.scrollHandler);
    },

    readItem(data) {
      this.items[data.key][data.readLabel] = true;
    },

    async load() {
      return new Promise((resolve, reject) => {
        (async () => {
          let self = this;
          self.loading = true;

          try {
            let items = await self.loadData(self.items.length, self.size);

            items = items.data;

            if (items.length > 0) {
              self.items = self.items.concat(items);
            } else {
              self.moreContentExists = false;

              self.store.actions.triggerCompAction("no-result", null);
            }

            resolve(true);
          } catch (err) {
            reject(err);
          }
        })();
      });
    },

    revealDown() {
      let self = this;
      self.startSlice += self.size / 2;
      self.endSlice += self.size / 2;
      // self.paddingTop += (self.size / 2) * self.itemHeight;
      self.paddingTop += self.firstHalfHeight();
      self.resumeScroll();
    },

    resumeScroll() {
      let self = this;
      let el = document.getElementsByClassName(self.parentElementClass)[0];
      el.style.overflow = "auto";
    },

    pauseScroll() {
      let self = this;
      let el = document.getElementsByClassName(self.parentElementClass)[0];
      el.style.overflow = "hidden";
    },

    firstHalfHeight() {
      let self = this;

      if (self.itemUniqueClass && self.itemMargin) {
        let totalHeight = 0;
        let halfOfListSize = self.size / 2;
        let items = document.querySelectorAll(
          "." + self.itemUniqueClass + ":nth-child(-n+" + halfOfListSize + ")"
        );

        for (let i = 0; i < halfOfListSize; i++) {
          let item = items[i];
          totalHeight += item.offsetHeight + self.itemMargin;
        }

        self.paddingTops.push(totalHeight);

        return totalHeight;
      } else {
        return (self.size / 2) * self.itemHeight;
      }
    },

    revealUp() {
      let self = this;
      self.startSlice -= self.size / 2;
      self.endSlice -= self.size / 2;
      self.paddingTop -= self.paddingTops.pop();

      self.$nextTick(() => {
        //Continue revealing up to catch up with user upwards scroll.
        let el = document.getElementsByClassName(self.parentElementClass)[0];
        let percentage_down =
          (el.scrollTop - self.paddingTop) /
          (el.scrollHeight - self.paddingTop);
        if (percentage_down < 0 && self.paddingTop > 0) {
          self.revealUp();
        }
      });

      self.resumeScroll();
    },

    onScroll(el) {
      let self = this;
      // let el = document.getElementById("home-page-container");
      self.percentage_down =
        (el.scrollTop + el.clientHeight - self.paddingTop) /
        (el.scrollHeight - self.paddingTop);

      /* console.log({
        scrollTop: el.scrollTop,
        paddingTop: self.paddingTop,
        scrollHeight: el.scrollHeight,
        percentage_down: self.percentage_down,
      }); */

      if (self.percentage_down > 0.8) {
        el.removeEventListener("scroll", self.onScroll);
        self.$nextTick(() => {
          el.addEventListener("scroll", self.onScroll);
        });

        let difference = self.items.length - self.endSlice;

        // console.log({
        //   type: "checkpoint",
        //   name: "difference & endslice",
        //   data: {
        //     difference: difference,
        //     workingLength: self.items.length,
        //     endSlice: this.endSlice,
        //     startSlice: this.startSlice,
        //     loading: self.loading,
        //     moreContentExists: self.moreContentExists,
        //     loadJam: self.loadJam,
        //     networkError_: self.networkError_,
        //   },
        // });

        if (difference > 0) {
          self.pauseScroll();
          self.revealDown();
        } else if (
          !self.loading &&
          self.moreContentExists &&
          !self.loadJam &&
          !self.networkError_
        ) {
          //Load more if: No loading is goin on, more content exists, we aren't already waiting for a load, and there is no network error
          self.loadMore();
        } else if (self.loading) {
          self.loadJam = true;
        }
      } else if (self.percentage_down < 0.2 && self.paddingTop > 0) {
        el.removeEventListener("scroll", self.onScroll);
        self.$nextTick(() => {
          el.addEventListener("scroll", self.onScroll);
        });
        self.pauseScroll();
        self.revealUp();
      } else if (el.scrollTop == 0) {
        self.$emit("scrolledToTop");
      }
    },

    loadMore() {
      let self = this;
      self
        .load()
        .then(() => {
          if (self.networkError_) {
            self.networkError_ = false;
          }

          if (self.loadJam) {
            if (self.percentage_down > 0.8) {
              let difference = self.items.length - self.endSlice;

              if (difference > 0) {
                self.revealDown();
              } else {
                self.loadMore();
              }
            }
            self.loadJam = false;
          }

          self.loading = false;
        })
        .catch((e) => {
          console.log(e);

          if (self.items.length > 0) {
            self.networkError_ = true;
            self.loading = false;

            self.reloadTimeout = setTimeout(() => {
              self.loadMore();
            }, 3000);
          } else {
            self.networkError_ = true;
            self.loading = false;

            self.store.actions.triggerCompAction("no-result", null);
          }
        });
    },

    /* loadFailed(callback = null) {
      let self = this;
      if (!callback) {
        callback = self.loadMore();
      }

      if (self.items.length > 0) {
        self.networkError_ = true;
        self.loading = false;

        self.reloadTimeout = setTimeout(() => {
          callback();
        }, 3000);
      } else {
        self.networkError_ = true;
        self.loading = false;
      }
    }, */

    probe() {
      console.log({
        items: this.items,
        workingLength: this.items.length,
        itemsInView: this.itemsInView,
        endSlice: this.endSlice,
        startSlice: this.startSlice,
      });
    },

    //Handler for list scroll
    scrollHandler() {
      let self = this;
      self.onScroll(self.parentEl);

      //If parent page has a scroll handler for the list, to react in its own specific way like
      //minimizing the header section when user scrolls up
      if (self.ParentListScrollHandler) {
        self.ParentListScrollHandler(self.parentEl);
      }
    },

    init() {
      //Initialize list
      let self = this;
      self.endSlice = self.size;
      self
        .load()
        .then(() => {
          this.loading = false;
          this.$emit("initialized"); //Let whatever parent elements needs to know we initialized. e.g To open banners
        })
        .catch((e) => {
          console.log(e);
          self.loading = false;
          self.networkError_ = true;

          self.store.actions.triggerCompAction("no-result", null);
        });

      //Bind scroll events
      self.parentEl.addEventListener("scroll", self.scrollHandler);
    },

    hardReset() {
      this.unbindParentScroll();

      Object.assign(this.$data, initState());

      let el = document.getElementsByClassName(this.parentElementClass)[0];
      el.scrollTop = 0;

      //Initialize list
      let self = this;
      self.endSlice = self.size;
      self
        .load()
        .then(() => {
          this.loading = false;
          this.loadJam = false;
          this.$emit("initialized"); //Let whatever parent elements needs to know we initialized. e.g To open banners
        })
        .catch((e) => {
          console.log(e);
          if (self.items.length > 0) {
            self.networkError_ = true;
            self.loading = false;

            self.reloadTimeout = setTimeout(() => {
              self.hardReset();
            }, 3000);
          } else {
            self.networkError_ = true;
            self.loading = false;

            self.store.actions.triggerCompAction("no-result", null);
          }
        });
      // self.loadMore();

      this.bindToLatestParentScrollDOM();
    },

    softReset(search = false) {
      let self = this;

      if (search) {
        self.loading = true;
      }

      let el = document.getElementsByClassName(self.parentElementClass)[0];
      el.style.scrollBehavior = "smooth";
      el.scrollTop = 0;

      let fetchData = new Promise((resolve) => {
        (async () => {
          self.loadJam = true;

          try {
            let items = await self.loadData(0, self.size);

            items = items.data;

            if (items.length > 0) {
              self.endSlice = self.size;
              self.startSlice = 0;
              self.paddingTop = 0;
              self.items = items;
              self.moreContentExists = true;
            } else {
              self.moreContentExists = false;
              self.store.actions.triggerCompAction("no-result", null);

              if (search) {
                self.endSlice = self.size;
                self.startSlice = 0;
                self.paddingTop = 0;
                self.items = reactive([]);
                self.loading = false;
                self.loadJam = false;
              }
            }

            resolve(true);
          } catch (err) {
            self.loadJam = false;

            if (search) {
              self.items = reactive([]);
              self.loading = false;
              self.networkError_ = true;
            }
            console.log(err);
          }
        })();
      });

      fetchData.then(() => {
        if (self.networkError_) {
          self.networkError_ = false;
        }

        if (self.loadJam) {
          self.loadJam = false;
        }

        if (self.loading) {
          self.loading = false;
        }

        //Scroll smooth to to
      });
    },

    parentViewWillLeave() {
      // let height = this.$refs.list.offsetHeight;
      // this.$refs.list.style.height = height + "px";
    },

    parentViewDidEnter() {
      // this.$refs.list.style.height = "unset";
    },

    exec(handler) {
      handler(this);
    },
  },

  computed: {
    itemsInView() {
      return this.items.slice(this.startSlice, this.endSlice);
    },

    server() {
      return this.store.server;
    },
  },

  watch: {
    actionTrigger() {
      this.actionHandler(this);
    },
  },

  mounted() {
    //Store the parent element in memory for DOM access
    this.parentEl = document.getElementsByClassName(this.parentElementClass)[0];

    //Emit self to parent for mutations
    this.$emit("recyclerInstance", this);
  },

  beforeUnmount() {
    //Remove event listeners from component on destroy to avoid memory leaks.
    //Like it still triggerring after getting destroyed.
    this.unbindParentScroll();
  },

  created() {},
};
</script>

<style scoped lang="scss">
.list {
  background: #fafafc;
  width: 100%;
}

#loading-more {
  text-align: center;
}
</style>