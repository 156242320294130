<template>
  <div id="no-result">
    <div class="image">
      <slot name="image"></slot>
    </div>

    <h4>{{ title }}</h4>

    <p v-html="description" class="description"></p>

    <p
      v-if="alert"
      :class="{
        alert: true,
        warning: alert?.type == 'warning',
        danger: alert?.type == 'danger',
        success: alert?.type == 'success',
        info: alert?.type == 'info',
      }"
      v-html="alert.text"
    ></p>

    <div class="line" :style="'width: ' + lineWidth" v-if="actionText"></div>

    <ion-button
      v-if="actionText"
      class=""
      fill="solid"
      expand="block"
      color="primary"
      @click="handlerArg ? clickHandler(handlerArg) : clickHandler()"
    >
      <span>{{ actionText }}</span>
    </ion-button>
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";

export default {
  props: {
    title: {
      required: true,
    },
    description: {
      required: true,
    },
    actionText: {
      required: true,
    },
    clickHandler: {
      required: true,
    },
    handlerArg: {
      default: null
    },
    lineWidth: {
      default: "33%",
    },

    alert: {
      default: null,
    }
  },
  components: {
    IonButton,
  },
};
</script>

<style scoped lang="scss">
#no-result {
  width: 100%;
  height: 100%;
  // background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0px;

  .image {
    width: 30%;
    margin-bottom: 50px;
  }

  p {
    position: relative;
    // margin-bottom: 56px;
    margin-top: 10px;
    color: #888;
    text-align: center;
    width: 80%;

    // &:after {
    //   content: "";
    //   width: 100%;
    //   height: 1px;
    //   background: #dedede;
    //   position: absolute;
    //   bottom: -29px;
    //   left: 0;
    // }
    &.alert {
      padding: 10px;
      border-radius: 15px;
      font-size: calc(14px + $extra-font-size);
      &.warning {
        color: #{darken($warning-color, 20%)};
        background: #{lighten($warning-color, 30%)};
      }

      &.info {
        color: #{darken($primary-color, 0%)};
        background: #{lighten($primary-color, 74%)};
      }
    }
  }

  h4 {
    font-weight: 800;
    color: #000;
  }

  .line {
    height: 1px;
    margin: 10px auto 23px;
    background: #dedede;
  }
}
</style>