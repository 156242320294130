<template>
  <no-result
    :title="lang.connection_error.title"
    :description="lang.connection_error.description"
    :actionText="lang.connection_error.action"
  >
    <template v-slot:image>
      <img src="../../../assets/img/network-error.png" alt="" srcset="" />
    </template>
  </no-result>
</template>

<script>
import NoResult from "./NoResult.vue";

export default {
  inject: ["store"],
  components: {
    NoResult,
  },
};
</script>

<style scoped lang="scss">
#no-result {
  width: 100%;
  height: 100%;
  // background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0px;

  .image {
    width: 50%;
    margin-bottom: 50px;
  }

  p {
    position: relative;
    margin-bottom: 56px;
    margin-top: 10px;
    color: #888;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #dedede;
      position: absolute;
      bottom: -29px;
      left: 0;
    }
  }

  h4 {
    font-weight: 800;
  }
}
</style>